<template>
  <div v-if="!loading">
    <div class="d-flex mt-4 mb-4 justify-center btn-group-info">
      <div class="d-flex flex-wrap flex-column align-center">
        <ion-fab-button size="small" class="custom-fab-btn" @click="() => router.push('/sale/about')">
          <ion-icon :icon="phonePortraitOutline" class="custom-fab-icon" />
        </ion-fab-button>
        <div class="col q-mt-sm">{{ $t('about_app') }}</div>
      </div>

      <div class="d-flex flex-wrap flex-column align-center mx-6">
        <ion-fab-button
          size="small"
          class="custom-fab-btn"
          @click="() => router.push('/sale/change-password')"
        >
          <ion-icon :icon="lockClosedOutline" class="custom-fab-icon" />
        </ion-fab-button>
        <div class="col q-mt-sm">
          {{ $t('accountPage.change') }}
          <br />{{ $t('accountPage.password') }}
        </div>
      </div>

      <div class="d-flex flex-wrap flex-column align-center">
        <ion-fab-button
          id="open-share-modal"
          size="small"
          class="custom-fab-btn"
          @click="setOpenModalShare(true)"
        >
          <ion-icon :icon="shareSocialOutline" class="custom-fab-icon" />
        </ion-fab-button>
        <div class="col q-mt-sm">{{ $t('share') }}</div>
      </div>
    </div>

    <div class="d-flex mt-4 justify-center btn-group-info">
      <div class="d-flex flex-wrap flex-column align-center">
        <ion-fab-button
          id="open-contact-modal"
          size="small"
          class="custom-fab-btn"
          @click="setOpenModalContact(true)"
        >
          <ion-icon :icon="callOutline" class="custom-fab-icon" />
        </ion-fab-button>
        <div class="col q-mt-sm">{{ $t('contact_us') }}</div>
      </div>
      <div class="d-flex flex-wrap flex-column align-center mx-3"></div>
      <div class="d-flex flex-wrap flex-column align-center">
        <a href="https://www.thetreedots.com/fnb-businesses-faq/">
          <ion-fab-button size="small" class="custom-fab-btn">
            <ion-icon :icon="helpOutline" class="custom-fab-icon" />
          </ion-fab-button>
        </a>
        <div class="col q-mt-sm">{{ $t('faq') }}</div>
      </div>
      <div class="d-flex flex-wrap flex-column align-center mx-3"></div>
      <div class="d-flex flex-wrap flex-column align-center" @click="setOpenModalLanguage(true)">
        <ion-fab-button size="small" class="custom-fab-btn">
          <ion-icon :icon="languageOutline" class="custom-fab-icon" />
        </ion-fab-button>

        <div class="col q-mt-sm">{{ $t('language') }}</div>
      </div>
    </div>

    <div class="d-flex justify-center mt-2">
      <ion-button
        class="fs-2 opacity-70 ion-text-uppercase"
        color="danger"
        fill="clear"
        size="small"
        @click="setOpenModalLogout(true)"
      >
        {{ $t('sign_out') }}
      </ion-button>
    </div>

    <div class="logo-version-container column mx-3 my-2">
      <div class="logo-version">
        <ion-img src="/assets/images/treedots_new_logo.svg" class="logo" />
        <div class="version">v {{ meta.version }}/{{ meta.htmlVersion }}</div>
      </div>
    </div>
  </div>

  <!-- modal Log out -->
  <ion-modal
    :is-open="isOpenModalLogoutRef"
    css-class="modal-confirm custom-modal"
    @didDismiss="setOpenModalLogout(false)"
  >
    <modal-content-confirm
      :title="titleConfirmLogout"
      :content="contentConfirmLogout"
      @confirm-yes-action="logout"
      @confirm-no-action="setOpenModalLogout(false)"
    >
    </modal-content-confirm>
  </ion-modal>

  <!-- modal contact us -->
  <ion-modal
    ref="modal"
    mode="ios"
    css-class="modal-content"
    trigger="open-contact-modal"
    :initial-breakpoint="contactModalSize"
    :is-open="isOpenModalContactRef"
    @didDismiss="setOpenModalContact(false)"
  >
    <contact-us />
  </ion-modal>

  <!-- modal share -->
  <ion-modal
    ref="modal"
    mode="ios"
    css-class="modal-content"
    trigger="open-share-modal"
    :is-open="isOpenModalShareRef"
    :initial-breakpoint="shareModalSize"
    @didDismiss="setOpenModalShare(false)"
  >
    <social-media-share @close-modal="setOpenModalShare(false)" />
  </ion-modal>
  <!-- modal language -->
  <ion-modal :is-open="isOpenModalLanguage" mode="ios" @didDismiss="setOpenModalLanguage(false)">
    <ion-page>
      <ion-header mode="ios" class="ion-no-border">
        <ion-toolbar>
          <ion-buttons slot="start" hide-back-button="true">
            <ion-back-button
              text=""
              @click="setOpenModalLanguage(false)"
              default-href="/sale/account"
            ></ion-back-button>
          </ion-buttons>
          <ion-title>{{ $t('language_preference') }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content><language-preference @closeModal="setOpenModalLanguage(false)" /></ion-content>
    </ion-page>
  </ion-modal>
  <!-- loading -->
  <ion-loading
    :is-open="isOpenLoadingref"
    @didDismiss="setOpenLoading(false)"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
  >
  </ion-loading>
</template>

<script>
import meta from '@/../package.json';
import ModalContentConfirm from '@/modules/sale/views/shared/ModalContentConfirm.vue';
import LanguagePreference from '@/modules/shared/views/LanguagePreference/index.vue';
import branch from '@/services/shared/helper/branch';
import PushNotification from '@/services/shared/helper/push-notification';
import { ACTIONS } from '@/store/actions';
import {
  callOutline,
  helpOutline,
  languageOutline,
  lockClosedOutline,
  phonePortraitOutline,
  shareSocialOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers, mapActions } from 'vuex';
import ContactUs from './partials/ContactUs.vue';
import SocialMediaShare from './partials/SocialMediaShare.vue';
const { mapGetters } = createNamespacedHelpers('sale/account');

export default defineComponent({
  name: 'ExtraInfo',
  components: {
    ModalContentConfirm,
    LanguagePreference,
    ContactUs,
    SocialMediaShare
  },
  data() {
    return {
      meta
    };
  },
  computed: {
    ...mapGetters(['loading']),
    titleConfirmLogout() {
      return this.$t('sign_out');
    },
    contentConfirmLogout() {
      return this.$t('are_you_sure');
    },
    shareModalSize() {
      const screenSize =
        screen.height <= 600
          ? 0.55
          : screen.height > 600 && screen.height <= 800
          ? 0.45
          : screen.height > 800 && screen.height <= 900
          ? 0.37
          : 0.34;
      return screenSize;
    },
    contactModalSize() {
      const screenSize =
        screen.height <= 600
          ? 0.65
          : screen.height > 600 && screen.height <= 655
          ? 0.59
          : screen.height > 655 && screen.height <= 800
          ? 0.51
          : screen.height > 800 && screen.height <= 889
          ? 0.43
          : 0.39;
      return screenSize;
    }
  },
  setup() {
    const isOpenModalLogoutRef = ref(false);
    const setOpenModalLogout = (state) => (isOpenModalLogoutRef.value = state);
    const isOpenModalContactRef = ref(false);
    const setOpenModalContact = (state) => (isOpenModalContactRef.value = state);
    const isOpenModalShareRef = ref(false);
    const setOpenModalShare = (state) => (isOpenModalShareRef.value = state);
    const router = useRouter();
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const isOpenModalLanguage = ref(false);
    const setOpenModalLanguage = (state) => (isOpenModalLanguage.value = state);
    return {
      router,
      phonePortraitOutline,
      lockClosedOutline,
      shareSocialOutline,
      callOutline,
      helpOutline,
      languageOutline,
      isOpenModalLogoutRef,
      setOpenModalLogout,
      isOpenModalContactRef,
      setOpenModalContact,
      isOpenModalShareRef,
      setOpenModalShare,
      isOpenModalLanguage,
      setOpenModalLanguage,
      isOpenLoadingref,
      setOpenLoading
    };
  },
  inject: ['$storage'],

  methods: {
    ...mapActions([ACTIONS.RESET_STATE]),
    async logout() {
      this.setOpenLoading(true);
      const user = await this.$storage.getUser();
      await Promise.allSettled([
        this[ACTIONS.RESET_STATE](),
        this.$storage.clearStorage(),
        PushNotification.removeAllListenerAndSubscribe(user?.id),
        branch.logout()
      ]);
      this.setOpenModalLogout(false);
      this.setOpenLoading(false);
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
    }
  }
});
</script>
<style lang="scss" src="@/assets/css/newdesign.scss" scoped></style>

<style scoped>
.custom-fab-btn {
  --background: linear-gradient(to bottom, #ffc107 50%, #eab109 50%);
}
.custom-fab-icon {
  font-size: 24px;
}

.btn-group-info {
  font-size: 12px;
  color: #eab109;
}

.logo-version-container {
  position: absolute;
  bottom: 0;
  right: 0;
}
.logo-version-container .logo-version {
  text-align: center;
}
.logo-version-container .logo-version .logo {
  width: 42px;
  height: 42px;
  border: 1px solid #ececec;
  border-radius: 0.25rem;
  margin-bottom: 0.135rem;
  background: #ffffff;
}
.logo-version-container .logo-version .version {
  color: #888;
  font-size: 11px;
}
@media only screen and (max-width: 599px) {
  .logo-version-container {
    position: relative;
    text-align: center;
  }
}
.logo-version {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  max-width: 150px;
  padding: 4px;
}
.modal-content {
  --border-radius: 20px 20px 0 0;
}
</style>
